
.bg-container {
    position: relative;
  }
  
  .bg-image {
    width: 100%;
  }
  
  .text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: white;
  }

  .fw-500{
    font-weight: 500;
  }

  .container-pc{
    margin-right: 125px !important;
    margin-left: 125px !important;
    height: 300px;
  }

  .option-button-style{
    display: flex;
    flex-direction: row;
    background: #F4F6F7;
    width: 325px;
    border-radius: 10px;
    padding: 10px;
    align-items: center;
    justify-content: space-between;

    .arrow-image{
      --arrow-size:17px;
      width: var(--arrow-size);
      height: var(--arrow-size);
    }
    .flt_svg {
      visibility: hidden;
      position: absolute;
      width: 0px;
      height: 0px;
    }
    filter: url('#flt_tag');
  }

  .text-style{
    color: #62B8AE;
    margin: 0;
    font-size: 18px;
    font-weight: 400;

  }

.option-button-style.selected{
  background: #62B8AE;
  border-radius: 10px 0 0 10px;
  position: relative;
  .flt_svg {
    visibility: hidden;
    position: absolute;
    width: 0px;
    height: 0px;
  }
  filter: url('#flt_tag');

  .text-style{
    color: #FFFFFF;
  }
}

.option-button-style.selected::after{
  position: absolute;
  height: 100%;
  content: "";
  width: 20px;
  top: 0;
  left: 100%;
  background: #62B8AE;
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%);

}

.menu-style{
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 255px;
  width: 350px;
  overflow: scroll;
}


.menu-style::-webkit-scrollbar {
  display: none;
}
.dashboard{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.menu-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  gap: 5%;
}