.custom-Modal {
  position: fixed;
  z-index: 999;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  justify-content: center;
  align-items: center;
  display: flex;

}

.custom-content-modal {
  border-radius: 20px;
  background: white;
  min-width: 300px;
  max-width: 600px;
  min-height: 300px;
}
.custom-content-modal-temas {
  height: 100vh;
  background-color: white;
}

.modal-header {
  display: flex;
  align-items: center;

}

.btn-close-modal {
  border: none;
  background: none;
}

.text-custom{
  color: #62B8AE
}

