.filter-bar{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    min-height: 72px;
}


.normas-filter{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #F4F6F7;
    min-height: 72px;
}

.input-normas{
    width: 22%;
    margin: 20px;

}