.modal-dialog-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100% - 1rem);
  }
  
  .modal-obligaciones-content {
    width: 791px;
    height: 656px;
  }

  .close{
    border: none;
    background-color: #fff
  }

  .obligations-title{
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
  }

  .acordion-header{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    flex-wrap: wrap;
  }

  .acordion-title{
    font-size: 16px;
    line-height: 19.2px;
    color: #62B8AE;
  }

  .rotate{
    transform: rotate(180deg);
  }

  .text-label-modal{
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
    padding-left: 50px;
  }

  .textarea-acordion{
    height: 88px;
    border-radius: 5px;
    border: 1px solid #B6B6B6;
    resize: none;    
  }

  .input-acordion-border{
    border: 1px solid #B6B6B6
  }