.navbarnormas {
    display: flex;
    position: relative;
    border-bottom: 1px solid #ffffff;
    width: 90%;
    height: 45px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.tab {
    flex: 1;
    text-align: center;
    cursor: pointer;
    position: relative;
}

.tab.active {
    color: #464646;
}

.underline {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 25%;
    background-color: #26a69a;
    transition: left 0.4s ease;
}

.selects-container{
    width: 288px;
    border: 1px solid #62B8AE;
    justify-items: center;
    align-content: center; 
    align-items: baseline;
    text-align: center;
}