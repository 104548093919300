$primary: #5BBBAF;
$primary-hover: #51D3BC;
$dark: #2C2933;
$dark-light: #595959;
$primary-light:#EBFFF9;
$gray-light: #F4F6F7;
.offcanvas{z-index: 1051!important;}


@import '../node_modules/bootstrap/scss/bootstrap';

body {
  font-family: 'Lato', sans-serif;
  color: $dark;
}

.btn, section, .tutorial-intro, .tutorial-intro-img, .btn-group-checkbox span, .btn-group-checkbox img {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}

.bg-primary-light {
  background-color: $primary-light;
}

.bg-custom{
  background-color: rgb(216, 255, 250);
  border-radius: 20px;
  padding: 5px;
  padding-left: 10px;
}
.custom-text{
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary;
  font-size: 12px;
  gap: 5px;
}

.app-navbar {
  background: white;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  z-index: 2;
}
.app-navbar .bubble {
  font-size: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.dropdown-item {
  color: $dark-light;
}

.language .dropdown-item img {
  width: 18px;
  height: 18px;
}

.language-active img {
  width: 26px;
  height: 26px;
}
.language .nav-link::after {
  display: none;
}
.app-lead {
  background-image: url(images/bg-heading-01.png);
  background-position: center right;
  background-repeat: no-repeat;
  background-color: #242E2D;
  min-height: 169px!important;
  display: flex!important;
  align-items: center!important;
}
.app-lead-light {
  background-image: url(images/bg-heading-02.png);
  background-position: center right;
  background-repeat: no-repeat;
  background-color: #F4F6F7;
  min-height: 169px;
  display: flex;
  align-items: center;
}
.app-lead .title {
  color: white;
  font-size: 38px;
}
.app-lead-light .title {
  color: $dark;
  font-size: 38px;
  font-weight: bold;
}
.app-lead .lead {
  color: white;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.app-card {
  max-width: 1102px;
  min-height: 331px;
  padding: 1rem;
  display: grid;
}

.app-card-body {
  max-width: 100%!important;
  min-height: 100%!important;
  background: #F4F6F7;
  box-shadow: 1px 1px 12px 0px rgba(0, 0, 0, 0.10)!important;
  border-radius: 20px!important;
  margin: 60px;
}
.bg-white-important{
  background: white !important;
}

.bg-color-none{
  color: transparent !important;
}
.app-card-body.bg-light {
  box-shadow: 1px 1px 12px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px white inset!important;
}
.app-card .title {
  color: $dark!important;
  text-align: center!important;
  font-family: Lato!important;
  font-size: 38px!important;
  font-style: normal!important;
  font-weight: 700!important;
  line-height: 1!important;
}
.app-card .subtitle {
  color: $dark!important;
  text-align: center!important;
  font-family: Lato!important;
  font-size: 28px!important;
  font-style: normal!important;
  font-weight: 700!important;
  line-height: 1!important;
}

.custom-table{
  --bs-table-bg: none !important;
}
.btn-eleg {
  border-radius: 2rem!important;
  background: #FAFAFA;
  box-shadow: 0 0 0 3px $primary inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.06);
  color: $primary!important;
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  width: 140px;
  height: 43px;


  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
}
.btn-elegCustom {
  display: none !important;
}
.btn-eleg:hover {
  background: white;
  box-shadow: 0 0 0 1px $primary-hover inset, 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  color: $dark;
  border: none;
}
.btn-large {
  width: 256px;
  height: 63px;
}
.btn-small {
  font-size: 15px;
  height: auto;
  width: auto;
  padding: 10px 20px;
}
.text-link-eleg {
  color: $primary;
  text-decoration: none;
}
.text-link-eleg:hover {
  color: $dark;
}

.tutorial-intro {
  position: relative;
  display: grid;
  grid-template-areas: "a1";
  justify-content: center;
  align-items: center;
  margin-top: -40px;
  margin-bottom: -40px;
  margin-right: -70px;
}
.tutorial-intro-txt {
  grid-area: a1;
  order: 1;
  max-width: 365px;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: center;
}
.tutorial-intro-img {
  grid-area: a1;
}
.card-resume{
  border-radius: 20px;
background: $primary-light;
padding: 3rem;
}
.card-resume .subtitle {
  margin-top: -5rem;
  background-color: white;
  text-align: center;
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.app-lead-light.wizard .title {
  text-align: center;
  font-size: 24px;
}
.timeline {
  background: linear-gradient(270deg, rgba(98, 184, 174, 0.00) 0%, $primary 48.44%, rgba(98, 184, 174, 0.00) 100%);
  height: 2px!important;
  width: 100%!important;
  display: grid!important;
  position: absolute;
  bottom: 0!important;
  left: 0!important;
}
.steep-bubble {
  margin-top: -34px!important;
}
.bubble {
  position: absolute;
  margin-top:60px;
}
.static-tooltip {
  background-image: url(images/static-tooltip.svg)!important;
  background-position: center top;
  background-repeat: no-repeat!important;
  width: 100px!important;
  height: 28px!important;
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
  margin: 0 auto;
  transform: translateY(-2px)!important;
}
.static-tooltip span {
  color: $primary!important;
  text-align: center!important;
  font-size: 12px!important;
  font-weight: 500!important;
  padding-bottom: 7px!important;
}


.timeline .bubble {
  display: flex;
  width: 14px;
  height: 14px;
  box-shadow: 0 0 0 1px $primary!important;
  // background-color: $primary !important;
  // background-color: white;
  border-radius: 50%!important;
  // color: white !important;
}

.custom-stiles{
  background-color: $primary !important;
  width: 20px !important;
  height: 20px !important;
}
.active .static-tooltip {
  transform: translateY(-10px)!important;
}
.active .bubble {
  transform: scale(2)!important;
}
.filled .bubble {
  background-color: $primary!important;
}

.modal-dialog.modal-size-1 {
  max-width: 532px!important;
}
.modal-size-1 .modal-content {
  min-height: 330px;
}
.modal-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-control {
  border-radius: 3px;
}

.list-resolved-item {
  list-style: none;
  margin: 0!important;
  padding: 0!important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.resolved-item {
  width: 45rem;
  background-color:#EBFFF9 ;
  display: flex;
  align-items: center!important;
  justify-content: space-between!important;
  margin-bottom: 1rem!important;
  border-radius: 20px!important;
  
}
.item-list{
  border-radius: 20px 0px 0px 20px !important;
  padding: .2rem 1rem;

}
.select-item{
  background-color: white !important;
  border-radius: 0px 20px 20px 0px !important;
  padding: .4em 1rem;
  border: none;
  color: #5BBBAF;
  font-weight: 570;
  font-size: smaller;

}

.select-item button{
    border: none;
    background: none;
}



.resolved-item.filled {
  background-color: $primary-light;
}
.item-name{
  color: $dark;
  font-size: 15px;
  text-align: left;
  line-height: 1.2;
  font-weight: bold;
}
.item-status{
  color: $primary;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.h-40px{
  height: 40px;
}

/* image group buttons with checkbox */

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin-right: 15px;
  font: inherit;
  color: #5BBBAF;
  width: 1.15em;
  height: 1.15em;
  border: 0.10em solid;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;
}


input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}


input[type="checkbox"]:checked::before {
  transform: scale(1);
  background-color: #51D3BC; 
}


input[type="checkbox"]:disabled {
  border-color: darkgrey;
  background-color: #E0E0E0; 
}

input[type="checkbox"]:disabled::before {
  transform: scale(1);
  background-color: darkgrey; 
  border-color: #595959;
}

.margin-right-15px{
  margin-right: 18px;
}


.btn-group-checkbox-item {
  max-width: 120px;
  margin: 0 auto;
}

.btn-group-checkbox input[type="checkbox"] {
  display: none;
}

.btn-group-checkbox label {
  display: grid;
  position: relative;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.btn-group-checkbox label:before {
  color: $primary;
  font-family: "bootstrap-icons";
  content: "\F270";
  opacity: 0;
  display: block;
  border-radius: 2rem;
  position: absolute;
  top: -10px;
  right: 15px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}



.btn-group-checkbox-item [type="checkbox"] {
  display: none;
}
.btn-group-checkbox label img {
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
  margin: 0 auto;
}

.btn-group-checkbox-item span {
  color: $dark-light;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding: 7px 0;
  height: 48px;
  display: flex;
  align-items: center;
}

.btn-group-checkbox label:hover span {
  color: $primary;
}

.btn-group-checkbox label:hover img {
  filter: invert(16%) sepia(6%) saturate(2343%) hue-rotate(124deg) brightness(100%) contrast(86%);
}
.btn-group-checkbox :checked + label:before {
  opacity: 1;
  transform: scale(1.2);
}

.btn-group-checkbox :checked + label img {
  filter: invert(16%) sepia(6%) saturate(2343%) hue-rotate(124deg) brightness(100%) contrast(86%);
}

.btn-group-checkbox :checked + label span {
  color: $primary;
}
// codigo nuevo
.dashboard-menu .nav-pills .nav-link {
  background-color: $gray-light;
  margin-bottom: 0.5rem;
  text-align: left;
  font-size: .9rem;
  font-weight: normal;
  justify-content: space-between;
  display: flex;
  column-gap: 1rem;
  align-items: center;
  position: relative;
  height: 46px;
}
.dashboard-menu .nav-pills .nav-link.active {
  background-color: $primary;
  color: white;
}
.dashboard-menu .nav-pills .nav-link::after{
  background: url(images/nav-pills-active.svg) no-repeat right center;
  content: "";
  width: 19px;
  height: 100%;
  position: absolute;
  right: -15px;
  top: 0;
  opacity: 0;
  transition: all 0.15s ease-in-out;
}
.dashboard-menu .nav-pills .nav-link.active::after{
  opacity: 1;
}
.dashboard-menu .nav-pills .nav-link i {
  transform: scale(1);
  transition: all 0.15s ease-in-out;
}
.dashboard-menu .nav-pills .nav-link.active i {
  transform: scale(1.5);
}

.text-add-items{
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  text-align: left;
  color: #62B8AE;
  text-decoration: none;
  cursor: pointer;
}

.text-add-items:hover{
  color: #62B8AE;
}

.text-teams-active{
  font-size: 12px;
  font-weight: 600;
  line-height: 14.4px;
  text-align: right;
  color: #62B8AE;
}

.scrollable-list {
  height: 250px; /* Altura fija para el ul */
  overflow-y: auto; /* Habilitar desplazamiento vertical */
  border: none; /* Opcional: para visualizar el contenedor */
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.scrollable-list::-webkit-scrollbar-track {
  background: red !important; /* Color de fondo de la pista del scrollbar */
}

.scrollable-list::-webkit-scrollbar-thumb {
  background: yellow !important; /* Color del "pulgar" del scrollbar */
  border-radius: 10px; /* Bordes redondeados del pulgar */
}
.scrollable-list::-webkit-scrollbar-button {
  display: none; /* Ocultar las flechas */
}
.scrollable-list {
  scrollbar-width: thin; /* Ancho del scrollbar */
  scrollbar-color: #CCCCCC #E6E5E6; /* Color del pulgar y la pista del scrollbar */
}

// hasta aca

@media (min-width:1400px) {
  .app-navbar {
    height: 96px;
  }
}

@media (max-width:1260px) {
  .app-lead {
    background-position: center left;
  }
  .tutorial-intro {
    margin-top: -25px;
    margin-bottom: -28px;
    margin-right: 0;
  }
  .tutorial-intro-img {
    max-width: 100%;
  }
  .tutorial-intro-txt h3 {
    font-size: 1.25rem;
  }
  .app-card .title {
    font-size: 28px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .tutorial-intro {
    margin-top: 0;
    margin-bottom: 0;
    width: 387px;
    overflow: hidden;
    float: right;
    margin-right: -12px;
  }
  .tutorial-intro-img {
    max-width: inherit;
    width: 490px;
    margin-right: -116px;
  }
  .tutorial-intro-txt {
    max-width: 305px;
    margin: 0 0 0 70px;
    padding: 0 1rem;
  }
}
@media (max-width: 767px) {
  .tutorial-intro {
    margin-top: 30px;
  }
}
@media (max-width: 575px) {
  .tutorial-intro {
    margin-top: 30px;
    margin-bottom: 0;
  }
  .tutorial-intro-txt {
    max-width: inherit;
    padding: 0;
  }
  .tutorial-intro-img {
    display: none;
  }
  .steep-bubble:not(.active) {
    display: none;
  }
}
@media (max-width: 479px) {
  .card-resume {
    padding: 3rem 1rem;
  }
  .app-card {
    padding: 2rem;
  }
}

.mi-boton.btn:focus {
  outline: none !important;
}


