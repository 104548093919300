.table-expanded {
  padding: 10px;
  background-color: #fff;
}

.th-style {
  background-color: #464646;
  color: #f4f6f7;
  padding-bottom: 10px;
  padding-top: 10px;
  font-weight: lighter;
  text-align: center;
}

.combined-th,
.combined-td {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 459px;
}

.combined-th span,
.combined-td div {
  display: block;
}

table.sub-table th {
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
  text-align: center;
  border: 1px solid #dadada;
  padding: 15px;
}

table.sub-table-body td {
  text-align: center;
  vertical-align: sub;
}

.table-expanded-body {
  border: 1px solid #dadada;
  vertical-align: sub;
}

.th-subtable {
  padding: 30px 20px !important;
  text-align: start !important;
}

.th-subtable:nth-of-type(1) {
  width: 50%;
}
.th-subtable:nth-of-type(2) {
  width: 25%;
}
.th-subtable:nth-of-type(3) {
  width: 25%;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 0px 0px 20px 20px;
}
.pagination a {
  color: #00a86b;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination .numbers {
  display: flex;
}

.pagination li {
  margin: 0 10px;
}

.pagination .active {
  color: #000;
  border: 1px solid #ddd;
  border-radius: 94px;
}

div.registros-text {
  text-align: center;
  padding: 10px;
  font-size: 12px !important;
  color: #464646;
  /* width: 1128px; */
  height: 94px;
  background-color: #f1f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  line-height: 12px;
}

div.registros-text-subtable {
  text-align: center;
  padding: 10px;
  font-size: 12px !important;
  color: #464646;
  height: 32px;
  background-color: #f1f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  line-height: 12px;
}

.td-text-style {
  padding: 10px;
  font-size: 12px;
  font-weight: 700;
}

.subtable-pagination-container {
  width: 93%;
  margin: 0 auto;
  border-radius: 0px 0px 20px 20px;
  border: 1px solid #dadada;
}

tr.tr-divider-bottom td{
  position: relative!important;
}

tr.tr-divider-bottom::after{
  content: '' !important;
    position: absolute !important;
    left: 22% !important;
    width: 55% !important;
    border-bottom: 1px solid #DADADA !important;
}

.pagination a.disabled {
  color: #6c757d; 
  cursor: not-allowed; 
}

.select-filter-normas{
  border-radius: 0px !important;
  border-left: 1px solid #62B8AE;
}


.btn-filter-actives{
  border-radius: 2rem !important;
    background: #FAFAFA;
    box-shadow: 0 0 0 3px #5BBBAF inset, 0px 7px 15px 0px rgba(0, 0, 0, 0.06);
    color: #5BBBAF !important;
    font-family: sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: 1;
    /* width: 140px; */
    height: 35px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.remove-filter{
  margin-left: 5px;
  font-weight: 700;
}